import { get, writable } from "svelte/store";
import { v4 as uuidv4 } from 'uuid';
const user = writable({
    tokens: null
});
export var TaskTypes;
(function (TaskTypes) {
    TaskTypes[TaskTypes["Normal"] = 0] = "Normal";
    TaskTypes[TaskTypes["Daily"] = 1] = "Daily";
    TaskTypes[TaskTypes["MainGoal"] = 2] = "MainGoal";
})(TaskTypes || (TaskTypes = {}));
;
function createTasksStore() {
    let data = [];
    // Attempt to initialize tasks from localstorage
    if (window.localStorage.getItem("tasks")) {
        data = JSON.parse(window.localStorage.getItem("tasks"));
        // MIGRATION LOGIC
        // FIXME: Does not actually update localstorage with the changes until an update occurs 
        // Commenting because migration logic is no longer needed
        // Migration logic: If a task has no UUID, give it one
        // for (let i = 0; i < data.length; i++) {
        //     if (!data[i].id) {
        //         data[i].id = uuidv4();
        //     }
        // }
        // Migration 2: Adding types to values
        // for (let i = 0; i < data.length; i++) {
        //     if (!data[i].type) {
        //         data[i].type = TaskTypes.Normal;
        //     }
        //     if (data[i].isDaily !== undefined) {
        //         delete(data[i].isDaily);
        //     }
        // }
    }
    ;
    const tasksStore = writable(data);
    // Replace `set` and `update` with methods that handle localStorage logic
    function addTask(taskText, type = TaskTypes.Normal) {
        let id = uuidv4();
        let task = { text: taskText, type, id };
        tasksStore.update(allTasks => [...allTasks, task]);
        persist();
    }
    function removeTask(id) {
        function updater(allTasks) {
            allTasks = allTasks.filter(task => task.id != id);
            return allTasks;
        }
        tasksStore.update(updater);
        persist();
    }
    function persist() {
        let value = get(tasksStore);
        window.localStorage.setItem("tasks", JSON.stringify(value));
    }
    function setter(newData) {
        tasksStore.set(newData);
        persist();
    }
    return {
        subscribe: tasksStore.subscribe,
        addTask,
        removeTask,
        set: setter
    };
}
export const tasks = createTasksStore();
export { user };
